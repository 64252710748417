import React, { useState, useEffect, useContext } from "react";
import { Grid, Chip } from "@material-ui/core";
import { COLORS } from "../helpers/Colors";
import { makeStyles } from "@material-ui/core/styles";
import HospitalScoreCard from "../components/Hospital/HospitalScoreCard";
import GoogleMapsPlugin from "../components/Hospital/GoogleMapsPlugin";
import HospitalInfo from "../components/Hospital/HospitalInfo";
import CommAssStandards from "../components/Hospital/CommAssStandardsPart";
import { getHospitalInfo } from "../helpers/ServerData";
import { ScreenSizeContext } from "../contexts/ScreenSizeContext";

const useStyles = makeStyles(theme => ({
  topPart: {
    direction: "rtl",
    marginTop: "50px"
  },
  image: {
    height: "260px",
    marginTop: "10px",
    borderRadius: "20px"
  },
  hospitalName: {
    textAlign: "center",
    fontFamily: "Frutiger-Black",
    fontSize: "20px",
    marginBottom: "0px",
    marginTop: "0px"
  },
  hospitalDesc: {
    textAlign: "center",
    fontFamily: "Frutiger-Bold",
    fontSize: "16px",
    maxWidth: "370px",
    maxHeight: "90px",
    lineHeight: "1.7",
    marginTop: "10px",
    marginBottom: "0px",
    overflow: "hidden",
    wordBreak: "break-word"
  },
  commentPart: {
    width: "100%",
    direction: "rtl",
    display: "inline-flex",
    marginTop: "30px"
  },
  commentTitle: {
    marginRight: "100px",
    fontFamily: "Frutiger-Black",
    fontSize: "26px",
    marginTop: "20px",
    width: "280px",
    color: COLORS.secondaryRed
  },
  commentText: {
    width: "900px",
    fontSize: "18px",
    marginRight: "0px"
  },
  clickHere: {
    margin: "5px",
    color: COLORS.secondaryRed
  },
  chipLabel: {
    background: COLORS.secondaryRed,
    color: COLORS.white,
    margin: "5px",
    marginTop: "15px",
    fontFamily: "Frutiger-Roman",
    textTransform: "capitalize"
  }
}));

const Hospital = props => {
  const classes = useStyles();
  const [hospitalData, setHospitalData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    let pathname = props.history.location.pathname;
    const id = pathname.split("/")[2];
    getHospitalInfo(id).then(hospitalData => {
      setLoading(false);
      setHospitalData(hospitalData.acf);
    });
  }, [props]);

  return (
    <div>
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignItems="center"
        className={classes.topPart}
      >
        <Grid
          item
          xs={11}
          s={12}
          md={3}
          l={2}
          xl={3}
          container
          justify="center"
        >
          <div
            style={{
              height: "130px",
              margin: "5px",
              marginTop: "auto",
              marginBottom: "auto",
              textAlign: "center"
            }}
          >
            <p className={classes.hospitalName}>{hospitalData.name}</p>
            <Chip
              className={classes.chipLabel}
              size="medium"
              label={hospitalData.description}
            />
          </div>

          <div style={{ textAlign: "center" }}>
            {hospitalData.image ? (
              <img
                className={classes.image}
                alt={hospitalData.name}
                src={hospitalData.image}
              />
            ) : (
              <img
                className={classes.image}
                alt={hospitalData.name}
                src={require("../assets/egHospitalsH.png")}
              />
            )}
          </div>
        </Grid>
        <Grid
          item
          xs={11}
          s={11}
          md={3}
          l={3}
          xl={3}
          container
          alignItems="center"
          justify="center"
        >
          {hospitalData.score ? (
            <HospitalScoreCard score={hospitalData.score} />
          ) : null}
          <GoogleMapsPlugin hospitalInfo={hospitalData} />
        </Grid>
        <Grid item xs={11} s={11} md={5} l={5} xl={5}>
          <HospitalInfo hospitalInfo={hospitalData} />
        </Grid>
      </Grid>

      <CommAssStandards hospitalInfo={hospitalData} />
      <Grid container justify="center" style={{ background: "#f8f8f8" }}>
        <div
          style={{
            width: "80%",
            direction: "rtl",
            color: "#707070",
            fontSize: "16px",
            textAlign: "center",
            lineHeight: "2"
          }}
        >
          <p>
            هذا التقييم تم بناءا على مؤشرات حددها المستفيدين من الخدمات الصحية و
            تم مراعاة الحيادية أثناء التقييم الذى تم من خلال زيارات عشوائية
            للمنشأة. النتائج المعلنة تعبر عن وجهة نظر المقيمين وقت تقييمهم
            للمستشفى بناءًا على تجاربهم الشخصية ويحق لإدارة المستشفى طلب تقييم
            جديد في حالة اختلاف النتائج عن الواقع.
          </p>
        </div>
      </Grid>
      {/*<div className={classes.commentPart}>
        <p className={classes.commentTitle}>تعليق فريق التقييم</p>
         <p className={classes.commentText}>
          {hospitalData["review_team_comment"]}
        </p> 
      </div> */}
    </div>
  );
};

export default Hospital;
