import React, { useContext } from "react";
import { COLORS } from "../../helpers/Colors";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { ScreenSizeContext } from "../../contexts/ScreenSizeContext";

const useStyles = makeStyles(theme => ({
  card: {
    width: "100%",
    height: "120px",
    backgroundColor: COLORS.whiteSmoke,
    textAlign: "center",
    margin: "0px",
    borderRadius: "10px",
    marginLeft: "auto",
    marginRight: "auto"
  },
  text: {
    fontFamily: "Frutiger-Bold",
    fontSize: "16px",
    margin: "5px",
    width: "100%"
  },
  yellowBg: {
    backgroundColor: COLORS.neutralYellow,
    width: "80px",
    height: "40px",
    margin: "0px",
    borderRadius: "8px",
    alignSelf: "center",
    color: COLORS.white,
    fontFamily: "Frutiger-Black",
    fontSize: "20px",
    lineHeight: "40px"
  }
}));
const HospitalScoreCard = props => {
  const classes = useStyles();
  const score = props.score + "%";
  const { matchesMD } = useContext(ScreenSizeContext);

  return (
    <Grid
      className={classes.card}
      container
      justify="center"
      alignItems="center"
      style={{ marginTop: matchesMD ? "0px" : "10px" }}
    >
      <p className={classes.text}>المستشفى حققت</p>
      <p className={classes.yellowBg}>
        <span
          className={classes.yellowBg}
          style={{ fontSize: "18px", fontDamily: "Frutiger-Bold" }}
        >
          {score}
        </span>
      </p>
      <p className={classes.text}>من معايير التقييم المجتمعى</p>
    </Grid>
  );
};

export default HospitalScoreCard;
