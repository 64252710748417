import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "../../helpers/Colors";
import { Grid } from "@material-ui/core";
import NumberinCircle from "./NumberinCircle";
import { NavLink } from "react-router-dom";
import { ScreenSizeContext } from "../../contexts/ScreenSizeContext";
import { Chip } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  card: {
    direction: "rtl",
    borderRadius: "30px",
    backgroundColor: COLORS.whiteSmoke,
    boxShadow: "0px 3px 3px 0px #aaaaaa",
    overflow: "hidden",
    minHeight: "95px"
  },
  hospitalName: {
    color: COLORS.secondaryRed,
    fontSize: "16px",
    fontFamily: "Frutiger-Bold",
    margin: "5px"
  },
  info: {
    color: COLORS.black,
    fontSize: "14px",
    fontFamily: "Frutiger-Bold",
    margin: "0px"
  },
  link: {
    color: "inherit",
    textDecoration: "none"
  },
  text: {
    width: "40%",
    wordBreak: "break-word",
    overflow: "hidden",
    paddingRight: "5px"
  },
  image: {
    width: "100%",
    height: "auto",
    borderRadius: "20px"
  },
  chipLabel: {
    background: COLORS.gray,
    color: COLORS.white,
    margin: "5px",
    fontFamily: "Frutiger-Roman",
    textTransform: "capitalize"
  }
}));

const HospitalSummaryCard = props => {
  const to = "/hospital/" + props.id;
  const classes = useStyles();
  const { matchesSM } = useContext(ScreenSizeContext);

  return (
    <NavLink className={classes.link} to={to}>
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center"
        className={classes.card}
        // style={matchesMD ? { height: "200px" } : { height: "260px" }}
      >
        {/* hospital image */}
        <Grid item xs={3} sm={2} md={2} lg={2} xl={2} style={{ margin: "5px" }}>
          {props.image ? (
            <img className={classes.image} alt={props.name} src={props.image} />
          ) : (
            <img
              className={classes.image}
              alt={props.name}
              src={require("../../assets/egHospitalsH.png")}
            />
          )}
        </Grid>
        <Grid item xs={5} sm={7} md={7} lg={7} xl={8} style={{ margin: "5px" }}>
          <p className={classes.hospitalName}> {props.name}</p>
          <Chip
            className={classes.chipLabel}
            size="small"
            label={props.description}
          />
          <Chip
            className={classes.chipLabel}
            size="small"
            label={props.governorate}
          />
        </Grid>

        {/* <p className={classes.info}> تليفون: {props.telephone}</p> */}
        <Grid
          item
          xs={2}
          sm={2}
          md={2}
          lg={2}
          xl={1}
          style={{
            marginRight: "auto",
            marginLeft: "5px"
          }}
        >
          <NumberinCircle
            number={props.review}
            size={matchesSM ? "large" : "small"}
          />
        </Grid>
      </Grid>
    </NavLink>
  );
};

export default HospitalSummaryCard;
