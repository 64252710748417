import React, { useContext, useEffect } from "react";
import { COLORS } from "../helpers/Colors";
import { makeStyles } from "@material-ui/core/styles";

import search from "../assets/icons/search.svg";
import ResultCard from "../components/SearchResults/ResultCard";
import { Grid, Input, Button } from "@material-ui/core";
import SearchResultsSideBar from "../components/SearchResults/SearchResultsSideBar";
import { SearchContext } from "../contexts/SearchContext";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ScreenSizeContext } from "../contexts/ScreenSizeContext";

const useStyles = makeStyles(theme => ({
  body: {
    direction: "rtl",
    margin: "20px",
    minHeight: "75.4vh"
  },
  searchWordIcon: {
    display: "inline-flex",
    alignItems: "center",
    marginRight: "20px"
  },
  resultsWord: {
    fontFamily: "Frutiger-Bold",
    fontSize: "20px"
  },
  searchIcon: {
    width: "35px",
    height: "40px",
    marginLeft: "6px"
  },
  searchInputField: {
    borderRadius: "50px",
    border: "2px solid",
    borderColor: COLORS.secondaryRed,
    marginRight: "10px",
    width: "100%",
    height: "50px",
    fontSize: "20px",
    direction: "rtl",
    paddingRight: "20px",
    paddingLeft: "20px"
  },
  searchButton: {
    marginRight: "30px",
    backgroundColor: COLORS.secondaryRed,
    color: COLORS.white,
    fontSize: "16px",
    fontFamily: "Frutiger-Roman",
    borderRadius: "50px",
    width: "110px",
    height: "50px"
  },
  label: {
    color: COLORS.secondaryRed,
    fontFamily: "Frutiger-Roman",
    fontSize: "16px",
    marginRight: "6px"
  },
  gps: {
    width: "30px",
    height: "30px",
    color: COLORS.secondaryRed,
    marginRight: "15px"
  }
}));
const SearchResults = () => {
  const classes = useStyles();
  const { matchesMD } = useContext(ScreenSizeContext);

  const {
    searchInput,
    setSearchInput,
    handleSearch,
    loading,
    searchResults,
    pagesCount,
    currPage,
    setCurrPage,
    comboFiltersPicked
  } = useContext(SearchContext);

  useEffect(() => {
    handleSearch(currPage);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setCurrPage(1);
  }, [searchInput, comboFiltersPicked]);

  const handleChangePage = direction => {
    // left
    if (direction === 1 && currPage < pagesCount) {
      setCurrPage(currPage + 1);
      handleSearch(currPage + 1);
      // right
    } else if (direction === -1 && currPage > 1) {
      setCurrPage(currPage - 1);
      handleSearch(currPage - 1);
    }
  };

  const renderLoading = () => (
    <Grid
      item
      xs={12}
      sm={12}
      md={9}
      lg={9}
      xl={9}
      container
      justify="center"
      direction="column"
      alignItems="center"
    >
      <CircularProgress
        style={{ marginTop: "90px", color: COLORS.secondaryRed }}
      />
    </Grid>
  );

  const renderSearchResults = () => (
    <Grid
      item
      xs={12}
      sm={12}
      md={9}
      lg={9}
      xl={9}
      container
      justify="center"
      direction="column"
      alignItems="flex-start"
    >
      {searchResults.map((searchResult, index) => (
        <ResultCard
          key={index}
          index={index + (currPage - 1) * 12 + 1}
          name={searchResult.acf.name}
          description={searchResult.acf.description}
          address={searchResult.acf.address}
          percentage={searchResult.acf.score}
          lastReviewDate={searchResult.acf.lastReviewDate}
          image={searchResult.acf.image}
          id={searchResult.id}
        />
      ))}
      <Grid
        container
        direction="row"
        justify="flex-end"
        style={{ maxWidth: "900px" }}
      >
        <IconButton
          aria-label="delete"
          className={classes.margin}
          size="small"
          disabled={currPage === 1}
          onClick={() => handleChangePage(-1)}
        >
          <ArrowRight
            style={{
              width: "60px",
              height: "60px",
              color: currPage === 1 ? COLORS.disabledGray : COLORS.black
            }}
          />
        </IconButton>
        <IconButton
          aria-label="delete"
          className={classes.margin}
          size="small"
          disabled={currPage === pagesCount}
          onClick={() => handleChangePage(1)}
        >
          <ArrowLeft
            style={{
              width: "60px",
              height: "60px",
              color:
                currPage === pagesCount ? COLORS.disabledGray : COLORS.black
            }}
          />
        </IconButton>
      </Grid>
    </Grid>
  );
  const renderNoResults = () => (
    <Grid
      item
      xs={12}
      sm={12}
      md={8}
      lg={7}
      xl={6}
      container
      justify="center"
      direction="column"
      alignItems="flex-start"
    >
      <p style={{ fontSize: "18px" }}>
        لا يوجد نتائج لهذا البحث، جرب كلمة بحث أخرى أو تصفيات أخرى
      </p>
    </Grid>
  );

  return (
    <div className={classes.body}>
      <Grid container alignItems="center">
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
          xl={3}
          container
          alignItems="center"
        >
          <div className={classes.searchWordIcon}>
            <img alt="search" src={search} className={classes.searchIcon} />
            <p className={classes.resultsWord}>نتائج البحث عن مستشفيات</p>
          </div>
        </Grid>
        <Grid item xs={12} sm={7} md={6} lg={5} xl={5}>
          <Input
            className={classes.searchInputField}
            disableUnderline={true}
            placeholder="اكتب إسم المستشفى هنا "
            type={"text"}
            value={searchInput}
            onChange={e => setSearchInput(e.target.value)}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          md={2}
          lg={3}
          xl={1}
          container
          justify="center"
        >
          <Button
            variant="contained"
            className={classes.searchButton}
            style={{ marginTop: matchesMD ? "0px" : "10px" }}
            onClick={() => handleSearch(currPage)}
          >
            ابحث
          </Button>
        </Grid>
      </Grid>

      <Grid
        container
        justify="flex-start"
        alignItems="flex-start"
        style={{ marginTop: "40px" }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
          xl={3}
          container
          justify={matchesMD ? "flex-start" : "center"}
          alignItems="flex-start"
        >
          <SearchResultsSideBar />
        </Grid>

        {loading == true
          ? renderLoading()
          : searchResults.length == 0
          ? renderNoResults()
          : renderSearchResults()}
      </Grid>
    </div>
  );
};
export default SearchResults;
