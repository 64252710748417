import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import facebook from "../../assets/icons/Facebook.svg";
import twitter from "../../assets/icons/twitter.svg";

const useStyles = makeStyles(theme => ({
  socialNetworkIcon: {
    width: "35px",
    height: "auto",
    margin: "0px 5px 0px 5px"
  }
}));

const SocialMedia = props => {
  const classes = useStyles();
  return (
    <div>
      <a
        href="https://twitter.com/shamseyaeg?lang=en"
        title="Twiiter"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          alt="twitter"
          src={twitter}
          className={classes.socialNetworkIcon}
        />
      </a>
      <a
        href="http://facebook.com/shamseyaeg/"
        title="Facebook"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          alt="facebook"
          src={facebook}
          className={classes.socialNetworkIcon}
        />
      </a>
    </div>
  );
};

export default SocialMedia;
