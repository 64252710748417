import React, { useState, useEffect } from "react";
import { COLORS } from "../../helpers/Colors";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  card: {
    width: "100%",
    height: "260px",
    backgroundColor: COLORS.whiteSmoke,
    textAlign: "center",
    margin: "0px",
    marginTop: "20px"
  }
}));
const GoogleMapsPlugin = props => {
  const classes = useStyles();
  const [src, setsrc] = useState("");
  useEffect(() => {
    let currsrc = "";
    if (props.hospitalInfo["google_frame"]) {
      currsrc = props.hospitalInfo["google_frame"].split("src=")[1];
      currsrc = currsrc.substr(1);
      currsrc = currsrc.split("width=")[0];
      currsrc.slice(0, currsrc.length - 1);
      currsrc += "&output=embed";
    } else if (
      props.hospitalInfo["location_longitude"] &&
      props.hospitalInfo["location_latitude"]
    ) {
      currsrc =
        "https://maps.google.com/maps?q=" +
        props.hospitalInfo["location_latitude"] +
        "," +
        props.hospitalInfo["location_longitude"] +
        "&hl=es;z=14&amp;&output=embed";
    }
    setsrc(currsrc);
  }, [props]);

  return (
    <div className={classes.card}>
      <iframe
        title={props.hospitalInfo.title}
        src={src}
        width="100%"
        height="260px"
        frameBorder="0"
      ></iframe>
    </div>
  );
};

export default GoogleMapsPlugin;
