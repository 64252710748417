import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import about_us from "../../assets/icons/about_us.svg";
import share_your_opinion from "../../assets/icons/share_your_opinion.svg";
import join_team from "../../assets/icons/join_team.svg";
import search from "../../assets/icons/search.svg";
import LabeledIcon from "./LabeledIcon";

const useStyles = makeStyles(theme => ({
  navigation: {
    display: "inline-flex",
    alignItems: "center",
    height: "70px",
    maxWidth: "100%"
  }
}));
const Navigation = () => {
  const classes = useStyles();
  return (
    <div className={classes.navigation}>
      {/* <LabeledIcon icon={about_us} label="عن البوابة" to="/about-us" />
      <LabeledIcon
        icon={share_your_opinion}
        iconSize="big"
        label="شارك برأيك"
        to="/share-with-us"
      />
      <LabeledIcon
        icon={join_team}
        iconSize="big"
        label="أنضم لفريق المقيميين"
        to="/join-us"
        btnSize="big"
      /> */}
      <LabeledIcon icon={search} label="بحث" to="/search" />
    </div>
  );
};

export default Navigation;
