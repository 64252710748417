import React, { useContext } from "react";
import { COLORS } from "../../helpers/Colors";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Chip } from "@material-ui/core";
import { NavLink } from "react-router-dom";

import PercentageCircle from "./PercentageCircle";
import { ScreenSizeContext } from "../../contexts/ScreenSizeContext";

const useStyles = makeStyles(theme => ({
  link: {
    color: "inherit",
    textDecoration: "none",
    width: "100%",
    maxWidth: "900px",
    marginBottom: "20px"
  },
  card: {
    backgroundColor: COLORS.whiteSmoke,
    textAlign: "center",
    direction: "rtl",
    borderRadius: "20px"
  },

  hospitalName: {
    textAlign: "right",
    fontFamily: "Frutiger-Bold",
    color: COLORS.secondaryRed,
    fontSize: "16px",
    margin: "3px",
    marginBottom: "0px",
    lineHeight: "1.4"
  },
  hospitalDesc: {
    fontSize: "14px",
    fontFamily: "Frutiger-Roman",
    marginTop: "0px",
    lineHeight: "1.4"
  },

  hospitalImage: {
    width: "100%",
    height: "auto",
    borderRadius: "20px"
  },
  chipLabel: {
    background: COLORS.gray,
    color: COLORS.white,
    fontFamily: "Frutiger-Roman",
    margin: "5px"
  }
}));

const ResultCard = props => {
  const classes = useStyles();
  const { matchesMD } = useContext(ScreenSizeContext);

  const to = "/hospital/" + props.id;
  return (
    <NavLink className={classes.link} to={to}>
      <Grid
        className={classes.card}
        container
        justify="flex-start"
        alignItems="center"
        style={{ direction: "rtl" }}
      >
        <Grid
          item
          xs={3}
          sm={2}
          md={2}
          lg={2}
          xl={2}
          style={{ margin: "10px" }}
        >
          {props.image ? (
            <img
              className={classes.hospitalImage}
              alt={props.name}
              src={props.image}
            />
          ) : (
            <img
              className={classes.hospitalImage}
              alt={props.name}
              src={require("../../assets/egHospitalsH.png")}
            />
          )}
        </Grid>
        <Grid
          item
          xs={5}
          sm={8}
          md={7}
          lg={7}
          xl={7}
          style={{
            margin: "10px",
            textAlign: "right"
          }}
        >
          <p className={classes.hospitalName}>{props.name}</p>
          <p className={classes.hospitalDesc}> {props.address} </p>

          <Chip
            className={classes.chipLabel}
            size="small"
            label={props.description}
          />
        </Grid>
        <Grid
          item
          xs={2}
          sm={1}
          md={2}
          lg={2}
          xl={2}
          style={{
            marginRight: "auto",
            marginLeft: "5px"
          }}
        >
          <PercentageCircle
            size={matchesMD ? "large" : "small"}
            percentage={props.percentage}
            date={props.lastReviewDate}
          />
        </Grid>
      </Grid>
    </NavLink>
  );
};

export default ResultCard;
