import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";

import HospitalSummaryCard from "./HospitalSummaryCard";
import { COLORS } from "../../helpers/Colors";
import { getRecentReviews } from "../../helpers/ServerData";

const useStyles = makeStyles(theme => ({
  body: {
    direction: "rtl",
    margin: "50px"
  },
  title: {
    fontFamily: "Frutiger-Bold",
    fontSize: "22px",
    fontColor: COLORS.black,
    marginRight: "40px"
  },
  showMore: {
    color: COLORS.secondaryRed,
    fontSize: "22px",
    textDecoration: "none",
    borderBottom: "2px solid",
    borderColor: COLORS.secondaryRed,
    fontFamily: "Frutiger-Bold"
  }
}));

const LastReviewedPart = () => {
  const classes = useStyles();
  const [hospitals, setHospitals] = useState([]);
  const [pagesCount, setPagesCount] = useState(0);
  const [currPage, setCurrPage] = useState(1);

  useEffect(() => {
    let newHospitals = [...hospitals];
    getRecentReviews(currPage).then(response => {
      setPagesCount(parseInt(response.totalPages));
      newHospitals = [...newHospitals, ...response.hospitals];
      setHospitals(newHospitals);
    });
  }, [currPage]);

  const handleChangePage = () => {
    setCurrPage(currPage + 1);
  };

  return (
    <div style={{ display: "inline-flex", justifyContent: "center" }}>
      {hospitals.length > 0 ? (
        <Grid
          container
          justify="flex-end"
          alignItems="center"
          item
          xs={12}
          s={12}
          md={12}
          lg={11}
          xl={11}
        >
          <p className={classes.title}>آخر ما تم تقييمه</p>
          <Grid container direction="row" justify="space-evenly">
            {hospitals.map((hospital, index) => (
              <Grid
                key={index + currPage}
                item
                xs={12}
                s={12}
                md={6}
                lg={5}
                xl={5}
                style={{ margin: "15px" }}
              >
                <HospitalSummaryCard
                  id={hospital.id}
                  name={hospital.acf.name}
                  description={hospital.acf.description}
                  governorate={hospital.acf.governorate}
                  telephone={hospital.acf["telephone_number"]}
                  review={hospital.acf.score}
                  image={hospital.acf.image}
                />
              </Grid>
            ))}
          </Grid>
          <div style={{ width: "90%", textAlign: "left", margin: "15px" }}>
            <Button
              className={classes.showMore}
              onClick={handleChangePage}
              disabled={currPage === pagesCount}
            >
              المزيد
            </Button>
          </div>
        </Grid>
      ) : null}
    </div>
  );
};

export default LastReviewedPart;
