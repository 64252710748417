import React, { useContext } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import Navigation from "./Navigation";
import { SearchContext } from "../../contexts/SearchContext";

const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: "rgba(256,256,256,0.9)",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    padding: "10px",
    top: "0",
    zIndex: "1",
    maxHeight: "85px",
    minHeight: "85px",
    position: "sticky",
    overflow: "hidden"
  },
  logo: {
    width: "100%",
    maxHeight: "80px"
  }
}));
const Header = () => {
  const classes = useStyles();
  const { setSearchInput } = useContext(SearchContext);

  const refreshContext = () => {
    setSearchInput("");
  };

  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      className={classes.header}
    >
      <Grid item xs={7} sm={4} md={3} lg={2} xl={3}>
        <NavLink to="/" onClick={refreshContext}>
          <img
            className={classes.logo}
            alt="logo"
            src={require("../../assets/eghospitals logo-H.png")}
          />
        </NavLink>
      </Grid>

      <Grid item xs={5} sm={5} md={2} container justify="flex-end">
        <Navigation />
      </Grid>
    </Grid>
  );
};

export default Header;
