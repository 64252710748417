import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "../../helpers/Colors";
import { Grid } from "@material-ui/core";
import Standard from "./Standard";

const useStyles = makeStyles(theme => ({
  body: {
    backgroundColor: COLORS.whiteSmoke,
    overflow: "auto",
    marginTop: "20px"
  },
  title: {
    color: COLORS.secondaryRed,
    fontSize: "22px",
    borderRadius: "30px",
    width: "290px",
    height: "60px",
    lineHeight: "60px",
    textAlign: "center",
    backgroundColor: COLORS.white,
    fontFamily: "Frutiger-Bold",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "50px"
  }
}));

const CommAssStandards = props => {
  const classes = useStyles();
  let image1 = require("../../assets/icons/Shield.svg");
  let image2 = require("../../assets/icons/medical-check2.svg");
  let image3 = require("../../assets/icons/medicine.svg");
  let image4 = require("../../assets/icons/first-aid-kit.svg");
  let image5 = require("../../assets/icons/pharmacy-copy.svg");
  let image6 = require("../../assets/icons/hospital-bed.svg");
  let image7 = require("../../assets/icons/doctor.svg");

  return (
    <div className={classes.body}>
      <p className={classes.title}>معايير التقييم المجتمعى</p>
      <Grid container justify="space-evenly" alignItems="center">
        <Grid
          container
          justify="center"
          item
          xs={12}
          s={12}
          md={5}
          lg={1}
          xl={1}
          style={{ marginRight: "20px" }}
        >
          <Standard
            image={image7}
            score={props.hospitalInfo["medical_team"]}
            label="الفريق الطبي"
            tooltip="يقاس من خلال ١٤ معيار بأوزان نسبية متنوعة تشمل جودة الفريق الطبي من أطباء وتمريض وفنيين ومعاونين ومؤهلاتهم وتدريبهم المستمر بالإضافة إلى تعاملهم مع المرضى والجودة العلمية للخدمات المقدمة ومدى إتباعهم للإرشادات وبروتوكولات التشخيص والعلاج"
          />
        </Grid>
        <Grid
          container
          justify="center"
          item
          xs={12}
          s={12}
          md={5}
          lg={1}
          xl={1}
          style={{ marginRight: "20px" }}
        >
          <Standard
            image={image6}
            score={props.hospitalInfo["inpatient"]}
            label="الإقامة الداخلية"
            tooltip="يقاس من خلال ٣٠ معيار بأوزان نسبية متنوعة تشمل الأقسام الداخلية بالمستشفى والإقامة وخدمات الفحوصات والمتابعة الدورية لحالات المرضى والتسجيل الطبي ومحددات النظافة والراحة بالغرف وغيرها"
          />
        </Grid>
        <Grid
          container
          justify="center"
          item
          xs={12}
          s={12}
          md={5}
          lg={1}
          xl={1}
          style={{ marginRight: "20px" }}
        >
          <Standard
            image={image5}
            score={props.hospitalInfo["clinics"]}
            label="العيادات"
            tooltip="يقاس من خلال ٢٦ معيار بأوزان نسبية متنوعة تشمل خدمات العيادات الخارجية بدءًا من سهولة الاستفسار والحجز وظروف الإنتظار وتعاون الفريق الطبي وجودة الخدمات الطبية المقدمة بالعيادات"
          />
        </Grid>
        <Grid
          container
          justify="center"
          item
          xs={12}
          s={12}
          md={5}
          lg={1}
          xl={1}
          style={{ marginRight: "20px" }}
        >
          <Standard
            big={true}
            image={image4}
            score={props.hospitalInfo["emergency"]}
            label="خدمات الطوارئ"
            tooltip="يقاس من خلال ١٧ معيار بأوزان نسبية متنوعة تشمل سرعة الاستجابة ودخول الاستقبال وتوفر الفريق الطبي المؤهل للتعامل مع الطوارئ وملائمة المكان وتجهيزه لاستقبال الحالات الطارئة وجودة الخدمات المقدمة بالطوارئ"
          />
        </Grid>
        <Grid
          container
          justify="center"
          item
          xs={12}
          s={12}
          md={5}
          lg={1}
          xl={1}
          style={{ marginRight: "20px" }}
        >
          <Standard
            image={image3}
            score={props.hospitalInfo["infrastructure_and_equipment"]}
            label="المباني والأجهزة والمستلزمات الطبية"
            tooltip="يقاس من خلال ١٤ معيار بأوزان نسبية متنوعة تشمل البنية التحتية للمستشفى من مباني وتشطيب ومعدات واجهزة ومفروشات بالإضافة إلى الأدوات والمستلزمات الطبية والأدوية بالإضافة إلى محددات الراحة للمرضى وضيوفهم"
          />
        </Grid>
        <Grid
          container
          justify="center"
          item
          xs={12}
          s={12}
          md={5}
          lg={1}
          xl={1}
          style={{ marginRight: "20px" }}
        >
          <Standard
            image={image2}
            score={props.hospitalInfo["patient_rights_and_care"]}
            label="العناية بالمرضى وحقوقهم"
            tooltip="يقاس من خلال ٥٢ معيار بأوزان نسبية متنوعة تشمل مدى احترام حقوق المرضى والالتزام بسرية المعلومات والخصوصية وتوفر المعلومات وسهولة الوصول والتسجيل والإجراءات المختلفة والاهتمام والعناية بالمرضى ومرافقيهم وحسن معاملتهم"
          />
        </Grid>
        <Grid
          container
          justify="center"
          item
          xs={12}
          s={12}
          md={5}
          lg={1}
          xl={1}
        >
          <Standard
            image={image1}
            score={props.hospitalInfo["safety"]}
            label="سلامة المرضى "
            tooltip="يقاس من خلال ٥٦ معيار بأوزان نسبية متنوعة تشمل إجراءات مكافحة العدوى وتجنب الأخطاء  الطبية والحماية من المخاطر المختلفة وتسجيل البيانات الطبية وإجراءات السلامة وخدمات الطوارئ وغيرها"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default CommAssStandards;
