import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "../../helpers/Colors";

const useStyles = makeStyles(theme => ({
  circle: {
    height: "70px",
    width: "70px",
    borderRadius: "50%",
    lineHeight: "75px",
    textAlign: "center",
    color: COLORS.white,
    fontFamily: "Frutiger-Bold",
    fontSize: "18px",
    marginRight: "auto",
    marginLeft: "5px"
  }
}));
const NumberinCircle = props => {
  const classes = useStyles();
  const [bg, setBg] = useState(COLORS.failRed);

  const colorCode = () => {
    if (props.number < 40) {
      setBg(COLORS.failRed);
    } else {
      if (props.number < 70) {
        setBg(COLORS.neutralYellow);
      } else {
        setBg(COLORS.successGreen);
      }
    }
  };

  let styles = {};
  if (props.size == "small") {
    styles.width = "50px";
    styles.height = "50px";
    styles.lineHeight = "50px";
    styles.fontSize = "14px";
  }
  styles.backgroundColor = bg;

  useEffect(() => {
    colorCode();
  }, [props.number]);

  return (
    <div className={classes.circle} style={styles}>
      {props.number}%
    </div>
  );
};

export default NumberinCircle;
