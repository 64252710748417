import React, { createContext, useState, useEffect } from "react";
import { getAllFilters, searchHandler } from "../helpers/ServerData";

export const SearchContext = createContext();

const SearchContextProvider = props => {
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({ isError: false, errorMessage: "" });
  const [filterData, setFilterData] = useState([]);

  const [comboFiltersPicked, setComboFiltersPicked] = useState([
    [],
    [],
    [],
    []
  ]);

  const [currPage, setCurrPage] = useState(1);
  const [pagesCount, setPagesCount] = useState(0);
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = (pageNumber, newCombo) => {
    setLoading(true);
    window.scrollTo(0, 0);
    if (!pageNumber) {
      pageNumber = currPage;
    }
    let searchWithCombo = [...comboFiltersPicked];
    if (newCombo) {
      searchWithCombo = newCombo;
    }

    searchHandler(searchWithCombo, searchInput, pageNumber)
      .then(results => {
        setPagesCount(parseInt(results.totalPages));
        setSearchResults(results.hospitals);
        setError({ isError: false });
        setLoading(false);
      })
      .catch(error => {
        setError({ isError: true, errorMessage: "NetworkError" });
      });
  };

  useEffect(() => {
    getAllFilters(setFilterData).then(values => {
      setFilterData(values);
    });
  }, []);

  return (
    <SearchContext.Provider
      value={{
        searchInput,
        setSearchInput,
        comboFiltersPicked,
        setComboFiltersPicked,
        filterData,
        setFilterData,
        handleSearch,
        currPage,
        setCurrPage,
        searchResults,
        loading,
        pagesCount,
        error
      }}
    >
      {props.children}
    </SearchContext.Provider>
  );
};

export { SearchContextProvider };
