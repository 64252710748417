import React from "react";
import Home from "./screens/Home";
import Header from "./components/Header/Header";
import { Switch, BrowserRouter, Route } from "react-router-dom";
import Footer from "./components/Footer";
import Hospital from "./screens/Hospital";
import SearchResults from "./screens/SearchResults";
import { SearchContextProvider } from "./contexts/SearchContext";
import ReactGA from "react-ga";
import { ScreenSizeContextProvider } from "./contexts/ScreenSizeContext";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  appSite: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh"
  },
  siteContent: {
    flex: "1 0 auto",
    padding: "1.5em 0 2em 0 0",
    width: "100%"
  }
}));
function App() {
  const classes = useStyles();

  const trackingId = "UA-66817414-1";
  ReactGA.initialize(trackingId);
  ReactGA.pageview("/");

  return (
    <div>
      <ScreenSizeContextProvider>
        <SearchContextProvider>
          <BrowserRouter>
            <div className={classes.appSite}>
              <div className={classes.siteContent}>
                <Header />
                <Switch>
                  <Route path="/" exact component={Home} />
                  <Route path="/search" exact component={SearchResults} />
                  <Route path="/hospital/:id" component={Hospital} />
                </Switch>
              </div>

              <Footer />
            </div>
          </BrowserRouter>
        </SearchContextProvider>
      </ScreenSizeContextProvider>
    </div>
  );
}

export default App;
