import axios from "axios";

export const LocalURL = "http://localhost/wordpress/index.php/wp-json/wp/v2/";

export const StagingURL = "http://staging.eghospitals.com/wp-json/wp/v2/";

export const API_URL = "https://api.eghospitals.com/wp-json/wp/v2/";

export const egHospitals = axios.create({
  baseURL: API_URL
});

export const egHospitalsStaging = axios.create({
  baseURL: StagingURL
});

export const egHospitalsLocal = axios.create({
  baseURL: LocalURL
});
