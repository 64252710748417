import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Copyright from "@material-ui/icons/Copyright";

import { COLORS } from "../helpers/Colors";
import SocialMedia from "./Header/SocialMedia";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  body: {
    backgroundColor: COLORS.footerBg,
    minHeight: "80px",
    width: "100%",
    textAlign: "center",
    overflow: "auto"
  },
  navLinks: {
    fontFamily: "Frutiger-Roman",
    textDecoration: "none",
    color: COLORS.black,
    fontSize: "14px"
  },
  copyRight: {
    fontFamily: "Frutiger-Roman",
    color: COLORS.black,
    fontSize: "14px",
    width: "100%",
    display: "contents"
  },
  copyRightIcon: {
    width: "20px",
    height: "auto",
    color: COLORS.secondaryRed,
    display: "inline-flex",
    margin: "-4px 4px 0px 0px"
  }
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.body}>
      {/* <div style={{ marginTop: "10px" }}>
        <NavLink className={classes.navLinks} to="/terms">
          {" "}
          Terms of Use{" "}
        </NavLink>
        .
      <NavLink className={classes.navLinks} to="/privacyPolicy">
          {" "}
          Privacy Policy{" "}
        </NavLink>
      </div> */}
      <Grid
        style={{ height: "95%", marginTop: "5px" }}
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Copyright className={classes.copyRightIcon} />
        <p className={classes.copyRight} style={{ background: "blue" }}>
          {"  "}
          2019 Developed and managed by{" "}
        </p>
        <p
          className={classes.copyRight}
          style={{ fontFamily: "Frutiger-bold" }}
        >
          Shamseya for Innovative Community Health Care Solutions
        </p>
        <Grid item xs={12} s={12} md={12} lg={12} xl={12}>
          <SocialMedia style={{ justifyContent: "center" }} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
