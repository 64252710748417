export const COLORS = {
  white: "#fff",
  black: "#000",

  secondaryRed: "#bf0711",
  hoverRed: "#cc7a7f",

  // Fail and Success
  failRed: "#b22222",
  successGreen: "#14b814",

  neutralYellow: "rgb(300,220, 60)",

  footerBg: "#969696",

  whiteSmoke: "#f2efef",
  whiteWhisper: "#EDEAEA77",
  whiteWhisperer: "#EDEAEA55",

  gray: "#747474",
  disabledGray: "#A9A9A9",
  lightGray: "#e0e0e0"
};
