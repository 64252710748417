import React, { useContext } from "react";

import { COLORS } from "../../helpers/Colors";
import { makeStyles } from "@material-ui/core/styles";
import FilterExpansion from "./FilterExpansion";
import { Grid } from "@material-ui/core";
import { ScreenSizeContext } from "../../contexts/ScreenSizeContext";
import { SearchContext } from "../../contexts/SearchContext";

const useStyles = makeStyles(theme => ({
  body: {
    height: "100%"
  },
  checkCircle: {
    color: COLORS.secondaryRed
  }
}));

const SearchResultsSideBar = props => {
  const classes = useStyles();
  const { matchesSM, matchesMD } = useContext(ScreenSizeContext);
  const { filterData } = useContext(SearchContext);

  return (
    <Grid
      container
      alignItems="flex-start"
      justify={matchesMD ? "flex-start" : "center"}
      style={{ marginRight: "10px" }}
    >
      <div
        className={classes.body}
        style={matchesSM ? { width: "70%" } : { width: "95%" }}
      >
        {filterData.map((row, index) => (
          <FilterExpansion i={index} key={index} data={row} />
        ))}
      </div>
    </Grid>
  );
};

export default SearchResultsSideBar;
