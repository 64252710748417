import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import { COLORS } from "../../helpers/Colors";

const useStyles = makeStyles(theme => ({
  navButton: {
    textAlign: "center"
  },
  navIcon: {
    width: "80px",
    height: "40px"
  },
  link: {
    "&.active": {
      "& p": {
        fontFamily: "Frutiger-Black"
      }
    }
  },
  label: {
    fontFamily: "Frutiger-Roman",
    fontSize: "14px",
    margin: "0px",
    color: COLORS.black
  }
}));

const LabeledIcon = props => {
  const classes = useStyles();

  return (
    <div
      className={classes.navButton}
      style={props.btnSize === "big" ? { width: "160px" } : { width: "110px" }}
    >
      <NavLink
        to={props.to}
        activeClassName={"active"}
        style={{ textDecoration: "none" }}
        className={classes.link}
      >
        <img
          alt="google plus"
          src={props.icon}
          className={classes.navIcon}
          style={
            props.iconSize === "big"
              ? { height: "60px", marginBottom: "10px" }
              : { height: "40px" }
          }
        />
        <p
          className={classes.label}
          style={
            props.iconSize === "big"
              ? { marginTop: "-22px" }
              : { marginTop: "0px" }
          }
        >
          {props.label}
        </p>
      </NavLink>
    </div>
  );
};

export default LabeledIcon;
