import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { COLORS } from "../../helpers/Colors";
import Search from "@material-ui/icons/Search";
import { getFilters } from "../../helpers/ServerData";
import Filters from "./Filters";
import { SearchContext } from "../../contexts/SearchContext";
import { ScreenSizeContext } from "../../contexts/ScreenSizeContext";

const useStyles = makeStyles(theme => ({
  background: {
    height: "100vh",
    backgroundImage: `url(${require("../../assets/images/homeBG.jpeg")})`,
    backgroundSize: "cover",
    marginTop: "-90px"
  },
  body: {
    maxWidth: "1000px",
    width: "100%",
    direction: "rtl",
    marginTop: "170px",
    textAlign: "center"
  },
  label: {
    color: COLORS.secondaryRed,
    fontFamily: "Frutiger-Roman",
    fontSize: "18px",
    marginRight: "10px",
    display: "inline-block"
  },
  opinionLabel: {
    color: COLORS.white,
    fontFamily: "Frutiger-Bold",
    fontSize: "16px",
    marginRight: "10px",
    display: "inline-flex",
    background: "rgba(0,0,0,0.5)",
    borderRadius: "20px",
    width: "270px",
    justifyContent: "center"
  },
  gps: {
    width: "40px",
    height: "40px",
    color: COLORS.secondaryRed,
    marginRight: "15px",
    marginBottom: "-15px"
  },
  searchBtn: {
    backgroundColor: COLORS.secondaryRed,
    color: COLORS.white,
    width: "130px",
    height: "50px",
    fontFamily: "Frutiger-Roman",
    borderRadius: "20px",
    textTransform: "none",
    fontSize: "20px",
    padding: "0px",
    "&:hover": {
      backgroundColor: COLORS.hoverRed
    }
  },
  navlink: {
    textDecoration: "none",
    lineHeight: "50px",
    color: "inherit",
    width: "130px",
    height: "50px",
    margin: "0px",
    borderRadius: "20px"
  },
  searchInputField: {
    backgroundColor: "rgba(256,256,256,0.7)",
    borderRadius: "20px",
    width: "95%",
    height: "50px",
    fontSize: "20px",
    paddingLeft: "20px",
    direction: "ltr"
  },
  iconBtn: {
    backgroundColor: COLORS.secondaryRed,
    width: "70px",
    height: "70px",
    marginRight: "-5px",
    "&:disabled": {
      backgroundColor: COLORS.secondaryRed,
      width: "70px",
      height: "70px"
    }
  },
  searchIcon: {
    color: COLORS.white,
    width: "35px",
    height: "35px"
  }
}));
const SearchPart = () => {
  const classes = useStyles();
  const [filterData, setFilterData] = useState([]);
  const { searchInput, setSearchInput } = useContext(SearchContext);
  const { matchesMD } = useContext(ScreenSizeContext);

  useEffect(() => {
    if (matchesMD) {
      getFilters(setFilterData);
    }
  }, [matchesMD]);

  let st = {};
  matchesMD === true ? (st.marginTop = "0px") : (st.marginTop = "10px");

  return (
    <Grid container justify="center" className={classes.background}>
      <Grid container justify="center" style={{ maxWidth: "1900px" }}>
        <Grid
          item
          xs={12}
          s={12}
          md={12}
          lg={7}
          xl={7}
          container
          justify="center"
          alignItems="center"
          className={classes.body}
        >
          <Grid
            container
            direction="row"
            justify="space-evenly"
            item
            xs={12}
            style={{ marginBottom: "-30px" }}
          >
            <Grid item xs={12} md={9} lg={9}>
              <Input
                className={classes.searchInputField}
                inputProps={{ style: { textAlign: "end" } }}
                disableUnderline={true}
                type={"text"}
                placeholder="ابحث عن مستشفى"
                value={searchInput}
                onChange={e => setSearchInput(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton disabled className={classes.iconBtn}>
                      <Search className={classes.searchIcon} />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item xs={12} md={"auto"} lg={2}>
              <Button
                style={st}
                variant="contained"
                className={classes.searchBtn}
              >
                <NavLink to="/search" className={classes.navlink}>
                  ابحث
                </NavLink>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          style={{
            overflowX: "auto",
            textAlign: "center",
            overflowY: "hidden"
          }}
        >
          {matchesMD == true ? (
            <Filters data={filterData} setData={setFilterData} />
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SearchPart;
