import React from "react";
import { COLORS } from "../../helpers/Colors";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  body: {
    width: "100px",
    marginRight: "auto",
    marginLeft: "auto"
  },
  circle: {
    width: "75px",
    height: "75px",
    lineHeight: "75px",
    borderRadius: "50%",
    color: COLORS.white,
    fontFamily: "Frutiger-Bold",
    fontSize: "18px"
  },
  lastReview: {
    fontFamily: "Frutiger-Roman",
    fontSize: "14px"
  }
}));
const PercentageCircle = props => {
  const classes = useStyles();
  let styles = {};

  props.percentage < 40
    ? (styles.backgroundColor = COLORS.failRed)
    : props.percentage < 70
    ? (styles.backgroundColor = COLORS.neutralYellow)
    : props.percentage <= 100
    ? (styles.backgroundColor = COLORS.successGreen)
    : (styles.backgroundColor = COLORS.white);

  if (props.size == "small") {
    styles.width = "50px";
    styles.height = "50px";
    styles.lineHeight = "50px";
    styles.fontSize = "14px";
  }

  return (
    <Grid
      container
      direction="column"
      justify={props.size == "small" ? "flex-end" : "center"}
      alignItems="center"
      className={classes.body}
      style={{ width: props.size == "small" ? "50px" : "100px" }}
    >
      <div className={classes.circle} style={styles}>
        {props.percentage}%
      </div>
      {props.date ? (
        <p className={classes.lastReview}>آخر تقييم: {props.date}</p>
      ) : null}
    </Grid>
  );
};

export default PercentageCircle;
