import { egHospitals } from "./Constants";

const retrieveFilters = () => {
  return new Promise((resolve, reject) => {
    egHospitals
      .get("categories?per_page=100")
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};
export const getAllFilters = () => {
  return new Promise((resolve, reject) => {
    Promise.all([getGovs(), getSpecialties(), getTypes(), getPrices()])
      .then(values => {
        resolve(values);
      })
      .then(error => {
        reject(error);
      });
  });
};

export const getPrices = () => {
  const path = "price";
  return new Promise((resolve, reject) => {
    egHospitals
      .get(path)
      .then(response => {
        resolve({ title: "الأسعار", options: response.data, id: 5 });
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const getTypes = () => {
  const path = "hospital_type";
  return new Promise((resolve, reject) => {
    egHospitals
      .get(path)
      .then(response => {
        resolve({ title: "نوع المستشفى", options: response.data, id: 4 });
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const getSpecialties = () => {
  const path = "categories?per_page=100";
  return new Promise((resolve, reject) => {
    egHospitals
      .get(path)
      .then(response => {
        let specialtiesData = response.data.filter(record => {
          if (record.parent === 3) return record;
        });
        resolve({ title: "التخصصات", options: specialtiesData, id: 3 });
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const getGovs = () => {
  const path = "tags?per_page=100";
  return new Promise((resolve, reject) => {
    egHospitals
      .get(path)
      .then(response => {
        resolve({ title: "المحافظة", options: response.data, id: 2 });
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const getFilters = setFilters => {
  const parents = [];
  const children = [];
  const finalFilters = [];
  // finalFilters  = [{title = "as3ar", id = "1", options: []}, {title = no3, id = "2", options: []} ]
  retrieveFilters().then(response => {
    response.map(category => {
      let record = {
        id: category.id,
        name: category.name
      };
      if ((category.parent === 0) & (category.name !== "Uncategorized")) {
        parents.push(record);
        let parentRecord = {
          title: category.name,
          id: category.id,
          options: []
        };
        finalFilters.push(parentRecord);
      } else {
        record.parent = category.parent;
        children.push(record);
      }
    });
    children.map(child => {
      finalFilters.map((parent, i) => {
        if (parent.id === child.parent) {
          finalFilters[i].options.push(child);
        }
      });
    });
    setFilters(finalFilters);
  });
};

export const getRecentReviews = page => {
  const path = "posts?per_page=6&page=" + page;
  return new Promise((resolve, reject) => {
    egHospitals
      .get(path)
      .then(response => {
        let newResponse = {
          totalPages: response.headers["x-wp-totalpages"],
          hospitals: [...response.data]
        };
        resolve(newResponse);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const getHospitalInfo = id => {
  const path = "posts/" + id;
  return new Promise((resolve, reject) => {
    egHospitals
      .get(path)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const searchHandler = (filters, searchInput, currPage) => {
  let path = "posts?";
  if (!(!searchInput || 0 === searchInput.length)) {
    path += "search=" + searchInput + "&";
  }
  if (filters && filters.length > 0) {
    filters.map((filterRow, i) => {
      if (filterRow.length > 0) {
        if (i == 0) {
          path += "tags=";
        } else if (i == 1) {
          path += "categories=";
        } else if (i == 2) {
          path += "hospital_type=";
        } else if (i == 3) {
          path += "price=";
        }
        path += filterRow.join(",");
        path += "&";
      }
      // if (filterRow.length > 0) {
      //   filterRow.map((filterId, j) => {
      //     if (i == 0) {
      //       if (j == 0) {
      //         path += "tags=";
      //       }
      //       path += filterId + "&";
      //     } else if (i == 1) {
      //       if (j == 0) {
      //         path += "categories=";
      //       }
      //       path += filterId + "&";
      //     } else if (i == 2) {
      //       if (j == 0) {
      //         path += "type=";
      //       }
      //       path += filterId + "&";
      //     } else if (i == 3) {
      //       if (j == 0) {
      //         path += "price=";
      //       }
      //       path += filterId + "&";
      //     }
      //   });
      // }
    });
  }
  path += "per_page=10&page=" + currPage;
  return new Promise((resolve, reject) => {
    egHospitals
      .get(path)
      .then(response => {
        let newResponse = {
          totalPages: response.headers["x-wp-totalpages"],
          hospitals: [...response.data]
        };
        resolve(newResponse);
      })
      .catch(error => {
        reject(error);
      });
  });
};
