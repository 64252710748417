import React from "react";
import SearchPart from "../components/Home/SearchPart";
import LastReviewedPart from "../components/Home/LastReviewedPart";
const Home = () => {
  return (
    <div>
      <SearchPart />
      <LastReviewedPart />
    </div>
  );
};

export default Home;
