import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "../../helpers/Colors";
import HelpIcon from "@material-ui/icons/Help";
import Tooltip from "@material-ui/core/Tooltip";
import { Grid, ClickAwayListener } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  body: {
    textAlign: "center",
    width: "100%",
    margin: "5px"
  },
  circle: {
    width: "100%",
    borderRadius: "50%",
    paddingTop: "100%",
    position: "relative",
    border: "4px solid #000000"
  },
  scoreBox: {
    fontFamily: "Frutiger-Black",
    width: "95px",
    height: "40px",
    lineHeight: "40px",
    borderRadius: "10px",
    color: COLORS.white,
    fontSize: "16px",
    marginTop: "-15px",
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
    backgroundColor: COLORS.neutralYellow
  },
  label: {
    fontFamily: "Frutiger-Roman",
    fontSize: "14px",
    marginTop: "20px",
    marginBottom: "5px",
    display: "block",
    lineHeight: "1.5"
  },
  image: {
    width: "70%",
    height: "auto",
    position: "absolute",
    marginTop: "-50%"
  },
  help: {
    width: "20px",
    height: "auto",
    color: COLORS.disabledGray
  }
}));

const Standard = props => {
  const classes = useStyles();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  let color;
  props.score <= 4
    ? (color = COLORS.failRed)
    : props.score <= 8
    ? (color = COLORS.neutralYellow)
    : props.score <= 10
    ? (color = COLORS.successGreen)
    : (color = COLORS.black);

  let imageStyles = {};
  if (props.big === true) {
    imageStyles.width = "110px";
    imageStyles.height = "110px";
    imageStyles.overflow = "hidden";
  } else {
    imageStyles.width = "110px";
    imageStyles.height = "110px";
  }
  return (
    <Grid
      container
      justify="center"
      direction="column"
      alignItems="center"
      className={classes.body}
    >
      <div style={{ maxWidth: "250px", width: "100%" }}>
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.circle}
          style={{
            borderColor: color
          }}
        >
          <img alt="standard" src={props.image} className={classes.image} />
        </Grid>
        {props.score ? (
          <div className={classes.scoreBox} style={{ background: color }}>
            {props.score} /10
          </div>
        ) : null}

        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
          style={{ height: "110px" }}
        >
          <p className={classes.label}>{props.label}</p>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
              placement="bottom"
              PopperProps={{
                disablePortal: true
              }}
              onClose={handleTooltipClose}
              open={tooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={
                <span
                  style={{
                    display: "block",
                    fontSize: "14px",
                    lineHeight: "1.5",
                    alignItems: "right",
                    textAlign: "center"
                  }}
                >
                  {props.tooltip}
                </span>
              }
            >
              <HelpIcon onClick={handleTooltipOpen} className={classes.help} />
            </Tooltip>
          </ClickAwayListener>
        </Grid>
      </div>
    </Grid>
  );
};

export default Standard;
