import React from "react";
import { COLORS } from "../../helpers/Colors";
import { makeStyles } from "@material-ui/core/styles";
import GPS from "@material-ui/icons/GpsFixed";
import Phone from "@material-ui/icons/Phone";
import Time from "@material-ui/icons/AccessTime";
import Dollar from "@material-ui/icons/AttachMoney";
import Team from "@material-ui/icons/Group";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  card: {
    width: "100%",
    // height: "400px",
    borderRadius: "20px",
    boxShadow: "4px -2px 12px 0 rgba(111, 111, 111, 0.27)",
    minHeight: "390px"
  },
  governorateText: {
    fontFamily: "Frutiger-Bold",
    fontSize: "20px",
    margin: "0px"
  },
  normalText: {
    fontFamily: "Frutiger-Roman",
    fontSize: "16px",
    margin: "0px",
    maxWidth: "94%",
    marginLeft: "10px",
    overflow: "hidden"
  },
  linkToMaps: {
    fontFamily: "Frutiger-Roman",
    fontSize: "16px",
    margin: "0px",
    maxWidth: "94%",
    marginLeft: "10px",
    color: COLORS.secondaryRed,
    textDecoration: "none"
  },
  icons: {
    color: COLORS.secondaryRed,
    marginRight: "30px"
  },
  hiddenColumn: {
    minWidth: "100px",
    textAlign: "center",
    hidden: true
  },
  iconColumn: {
    minWidth: "100px",
    textAlign: "center"
  },
  row: {
    display: "inline-flex",
    alignItems: "center",
    minWidth: "100%",
    marginTop: "10px"
  }
}));

const HospitalInfo = props => {
  const classes = useStyles();
  const emergency =
    props.hospitalInfo["full_day_emergency"] === true
      ? "الطوارىء 24 ساعة"
      : "طوارئ 24 ساعة ليست متوفرة ";

  let currsrc = "";
  if (props.hospitalInfo["google_frame"]) {
    currsrc = props.hospitalInfo["google_frame"].split("src=")[1];
    currsrc = currsrc.substr(1);
    currsrc = currsrc.split("width=")[0];
    currsrc.slice(0, currsrc.length - 1);
    // currsrc += "&output=embed";
  } else if (
    props.hospitalInfo["location_longitude"] &&
    props.hospitalInfo["location_latitude"]
  ) {
    currsrc =
      "https://maps.google.com/maps?q=" +
      props.hospitalInfo["location_latitude"] +
      "," +
      props.hospitalInfo["location_longitude"] +
      "&hl=es;z=14&amp;&output=embed";
  }
  let src = currsrc;

  return (
    // <div className={classes.card}>
    <Grid container alignItems="center" className={classes.card}>
      {props.hospitalInfo.governorate ? (
        <div className={classes.row}>
          <div className={classes.iconColumn}>
            <GPS className={classes.icons} />
          </div>
          <p className={classes.governorateText}>
            محافظة {props.hospitalInfo.governorate}
          </p>
        </div>
      ) : null}
      {props.hospitalInfo.address ? (
        <div className={classes.row}>
          <div className={classes.hiddenColumn}></div>
          <p className={classes.normalText}>{props.hospitalInfo.address}</p>
        </div>
      ) : null}
      {/* {(props.hospitalInfo["location_longitude"] &&
        props.hospitalInfo["location_latitude"]) ||
      props.hospitalInfo.google_frame ? (
        <div className={classes.row} style={{ color: COLORS.secondaryRed }}>
          <div className={classes.hiddenColumn}></div>
          <a
            className={classes.linkToMaps}
            href={src}
            target="_blank"
            rel="noopener noreferrer"
          >
            الاتجاهات علي Google Maps
          </a>
        </div>
      ) : null} */}
      {props.hospitalInfo["telephone_number"] ? (
        <div style={{ display: "block", minWidth: "100%" }}>
          <div className={classes.row}>
            <div className={classes.iconColumn}>
              <Phone className={classes.icons} />
            </div>
            <p className={classes.normalText}>
              {props.hospitalInfo["telephone_number"]}
            </p>
          </div>
        </div>
      ) : null}
      <div style={{ display: "block" }}>
        <div className={classes.row}>
          <div className={classes.iconColumn}>
            <Time className={classes.icons} />
          </div>
          {props.hospitalInfo["working_hours_from"] &&
          props.hospitalInfo["working_hours_from"] ? (
            <p className={classes.normalText}>
              المواعيد الرسمية للعيادات من{" "}
              {props.hospitalInfo["working_hours_from"]} حتى{" "}
              {props.hospitalInfo["working_hours_to"]}
            </p>
          ) : (
            <p className={classes.normalText}>
              تختلف مواعيد العيادات على حسب التخصصات{" "}
            </p>
          )}
        </div>
      </div>

      {props.hospitalInfo["full_day_emergency"] !== undefined ? (
        <div className={classes.row}>
          <div className={classes.hiddenColumn}></div>
          <p className={classes.normalText}> {emergency} </p>
        </div>
      ) : null}
      {props.hospitalInfo["checkup_fee"] ? (
        <div style={{ display: "block", marginTop: "10px" }}>
          <div className={classes.row}>
            <div className={classes.iconColumn}>
              <Dollar className={classes.icons} />
            </div>
            <p className={classes.normalText}>
              السعر الرسمي للكشف {props.hospitalInfo["checkup_fee"]} جنيه
            </p>
          </div>
        </div>
      ) : null}
      {props.hospitalInfo["review_team_count"] ? (
        <div style={{ display: "block", marginTop: "10px", minWidth: "100%" }}>
          <div className={classes.row}>
            <div className={classes.iconColumn}>
              <Team className={classes.icons} />
            </div>
            <p className={classes.normalText}>
              قام بهذا التقييم {props.hospitalInfo["review_team_count"]} فرق من
              المقيمين المجتمعيين للخدمات الصحية
            </p>
          </div>
        </div>
      ) : null}
      {props.hospitalInfo["last_review_date"] ? (
        <div
          style={{ display: "block", marginBottom: "5px", marginRight: "auto" }}
        >
          <p
            className={classes.normalText}
            style={{ color: COLORS.gray, fontSize: "15px" }}
          >
            آخر تقييم: {props.hospitalInfo["last_review_date"]}
          </p>
        </div>
      ) : null}
    </Grid>
  );
};

export default HospitalInfo;
