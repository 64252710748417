import React, { useContext } from "react";
import { COLORS } from "../../helpers/Colors";
import { makeStyles } from "@material-ui/core/styles";

import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import RemoveChoice from "@material-ui/icons/RemoveCircle";
import AddChoice from "@material-ui/icons/AddCircleOutline";
import { SearchContext } from "../../contexts/SearchContext";

const useStyles = makeStyles(theme => ({
  body: {
    minWidth: "300px",
    height: "100%"
  },
  checkCircle: {
    color: COLORS.secondaryRed
  },
  formLabelWidth: {
    width: "200px"
  },
  checboxWidth: {
    width: "50px"
  },
  zeroMargin: {
    margin: "0px"
  },
  option: {
    fontFamily: "Frutiger-Roman",
    margin: "0px"
  }
}));

const FilterExpansion = props => {
  const classes = useStyles();
  const {
    comboFiltersPicked,
    setComboFiltersPicked,
    handleSearch
  } = useContext(SearchContext);

  const filterClick = (id, row) => {
    let newCombo = [...comboFiltersPicked];
    if (newCombo[row].includes(id)) {
      newCombo[row] = newCombo[row].filter(record => record !== id);
    } else {
      newCombo[row].push(id);
    }
    setComboFiltersPicked(newCombo);
    handleSearch(1, newCombo);
  };
  return (
    <ExpansionPanel
      style={{
        borderRadius: "10px",
        fontFamily: "Frutiger-Black",
        marginBottom: "20px",
        fontSize: "18px"
      }}
    >
      <ExpansionPanelSummary
        className={classes.zeroMargin}
        expandIcon={<ExpandMoreIcon />}
      >
        <p className={classes.zeroMargin} style={{ fontSize: "16px" }}>
          {props.data.title}{" "}
        </p>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails
        style={{
          maxHeight: "190px",
          overflow: "auto",
          overflowX: "hidden",
          fontFamily: "Frutiger-Roman"
        }}
      >
        <div>
          {props.data.options.map(filter => (
            <FormControlLabel
              style={{ fontFamily: "Frutiger-Bold" }}
              key={filter.name}
              className={classes.formLabelWidth}
              control={
                <div className={classes.checkboxWidth}>
                  <Checkbox
                    icon={<AddChoice className={classes.checkCircle} />}
                    checkedIcon={
                      <RemoveChoice className={classes.checkCircle} />
                    }
                    checked={
                      comboFiltersPicked &&
                      comboFiltersPicked[props.i].includes(filter.id)
                    }
                    onChange={e => {
                      filterClick(filter.id, props.i);
                    }}
                  />
                </div>
              }
              label={<p className={classes.option}>{filter.name}</p>}
            />
          ))}
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default FilterExpansion;
