import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";

import { COLORS } from "../../helpers/Colors";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
import TextinOval from "./TextinOval";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import { SearchContext } from "../../contexts/SearchContext";

const useStyles = makeStyles(theme => ({
  widthandheight: {
    width: "950px",
    height: "360px"
  },
  body: {
    width: "920px",
    height: "330px",
    borderRadius: "20px",
    backgroundColor: COLORS.whiteWhisper,
    padding: "15px",
    direction: "rtl"
  },
  searchBtn: {
    backgroundColor: COLORS.secondaryRed,
    color: COLORS.white,
    width: "100px",
    height: "48px",
    fontFamily: "Frutiger-Roman",
    borderRadius: "20px",
    textTransform: "none",
    marginRight: "auto",
    marginTop: "10px",
    padding: "0px",
    fontSize: "18px",
    "&:hover": {
      backgroundColor: COLORS.hoverRed
    }
  },
  navlink: {
    textDecoration: "none",
    lineHeight: "48px",
    color: "inherit",
    width: "100px",
    height: "48px",
    margin: "0px",
    borderRadius: "20px"
  }
}));

const Filters = props => {
  const classes = useStyles();
  const [shownFilters, setShownFilters] = useState([]);
  const [shownFiltersStart, setShownFiltersStart] = useState([0, 0, 0, 0]);
  const { filterData, comboFiltersPicked, setComboFiltersPicked } = useContext(
    SearchContext
  );

  useEffect(() => {
    let newShownFilters = [];
    filterData.forEach((record, i) => {
      if (record.options.length >= 4) {
        newShownFilters.push(
          record.options.slice(shownFiltersStart[i], shownFiltersStart[i] + 4)
        );
      }
    });

    setShownFilters([...newShownFilters]);
  }, [props, shownFiltersStart]);

  // adding/removing 1 from the start index of the shown filters
  const arrowClick = (index, direction) => {
    let newShownFiltersStart = [...shownFiltersStart];
    if (direction === 0) {
      // Left
      if (newShownFiltersStart[index] + 5 <= filterData[index].options.length) {
        newShownFiltersStart[index] += 1;
      }
    } else {
      // Right
      if (newShownFiltersStart[index] - 1 >= 0) {
        newShownFiltersStart[index] -= 1;
      }
    }
    setShownFiltersStart([...newShownFiltersStart]);
  };
  // setting the filter as picked by adding its id to its corresponding place in combo
  const filterClick = (id, row) => {
    let newCombo = [...comboFiltersPicked];
    if (newCombo[row].includes(id)) {
      newCombo[row] = newCombo[row].filter(record => record != id);
    } else {
      newCombo[row].push(id);
    }
    setComboFiltersPicked(newCombo);
  };
  return (
    <div className={classes.widthandheight}>
      {filterData.length > 0 ? (
        <div className={classes.body} style={{ marginTop: "15px" }}>
          <Grid container direction="row" justify="center">
            {shownFilters.length > 0
              ? shownFilters.map((filterRow, i) => {
                  return (
                    <div
                      key={i}
                      style={{ display: "inline-flex", marginTop: "20px" }}
                    >
                      <TextinOval
                        text={filterData[i].title}
                        borderedRadius="all"
                        width="200px"
                        color={COLORS.secondaryRed}
                        fontFamily="Frutiger-Bold"
                      />
                      {filterData[i].options != null &&
                      filterData[i].options.length > 4 ? (
                        <ArrowRight
                          onClick={() => arrowClick(i, 1)}
                          style={{
                            width: "45px",
                            height: "45px",
                            color:
                              shownFiltersStart[i] - 1 >= 0
                                ? COLORS.black
                                : COLORS.disabledGray,
                            cursor:
                              shownFiltersStart[i] - 1 >= 0
                                ? "pointer"
                                : "default"
                          }}
                        />
                      ) : (
                        <div style={{ width: "45px" }}></div>
                      )}
                      <TextinOval
                        clickable={true}
                        text={
                          filterRow[0] && filterRow[0].name != undefined
                            ? filterRow[0].name
                            : ""
                        }
                        bordered="right"
                        borderedRadius="right"
                        width="160px"
                        color={
                          comboFiltersPicked[i].includes(filterRow[0].id)
                            ? COLORS.secondaryRed
                            : COLORS.black
                        }
                        selectFilter={() => filterClick(filterRow[0].id, i)}
                        fontFamily="Frutiger-Roman"
                        marginRight="0px"
                      />
                      <TextinOval
                        clickable={true}
                        text={
                          filterRow[1] && filterRow[1].name != undefined
                            ? filterRow[1].name
                            : ""
                        }
                        bordered="between"
                        borderedRadius="between"
                        width="160px"
                        color={
                          comboFiltersPicked[i].includes(filterRow[1].id)
                            ? COLORS.secondaryRed
                            : COLORS.black
                        }
                        fontFamily="Frutiger-Roman"
                        selectFilter={() => filterClick(filterRow[1].id, i)}
                      />
                      <TextinOval
                        clickable={true}
                        text={
                          filterRow[2] && filterRow[2].name != undefined
                            ? filterRow[2].name
                            : ""
                        }
                        bordered="left"
                        width="160px"
                        color={
                          comboFiltersPicked[i].includes(filterRow[2].id)
                            ? COLORS.secondaryRed
                            : COLORS.black
                        }
                        fontFamily="Frutiger-Roman"
                        selectFilter={() => filterClick(filterRow[2].id, i)}
                      />
                      <TextinOval
                        clickable={true}
                        text={
                          filterRow[3] && filterRow[3].name != undefined
                            ? filterRow[3].name
                            : ""
                        }
                        bordered="left"
                        borderedRadius="left"
                        width="160px"
                        color={
                          comboFiltersPicked[i].includes(filterRow[3].id)
                            ? COLORS.secondaryRed
                            : COLORS.black
                        }
                        fontFamily="Frutiger-Roman"
                        selectFilter={() => filterClick(filterRow[3].id, i)}
                      />
                      {filterData[i].options != null &&
                      filterData[i].options.length > 4 ? (
                        <ArrowLeft
                          onClick={() => arrowClick(i, 0)}
                          style={{
                            width: "45px",
                            height: "45px",
                            color:
                              shownFiltersStart[i] + 5 <=
                              filterData[i].options.length
                                ? COLORS.black
                                : COLORS.disabledGray,
                            cursor:
                              shownFiltersStart[i] + 5 <=
                              filterData[i].options.length
                                ? "pointer"
                                : "default"
                          }}
                        />
                      ) : (
                        <div style={{ width: "45px" }}></div>
                      )}
                    </div>
                  );
                })
              : null}
            <Button variant="contained" className={classes.searchBtn}>
              <NavLink to="/search" className={classes.navlink}>
                إذهب
              </NavLink>
            </Button>
          </Grid>
        </div>
      ) : null}
    </div>
  );
};

export default Filters;
