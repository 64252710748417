import React, { createContext } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

export const ScreenSizeContext = createContext();

const ScreenSizeContextProvider = props => {
  const theme = useTheme();

  const matchesSM = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <ScreenSizeContext.Provider
      value={{
        matchesSM,
        matchesMD
      }}
    >
      {props.children}
    </ScreenSizeContext.Provider>
  );
};

export { ScreenSizeContextProvider };
